import React from "react"
import { Link } from "gatsby"

export default function HeaderBtn({ blk, linkTo }) {
  return (
    <div className="mt-10 flex items-center flex-wrap">
      <Link to="/">
        <span className="font-body text-darkerBlue bg-lightGreen px-4 py-2 text-lg mr-8">
          Tell us about your project
        </span>
      </Link>
      <Link to={`${linkTo}`}>
        <span
          className={`font-body text-lg underline text-white ${
            blk && "text-darkerBlue"
          }`}
        >
          Learn More
        </span>
      </Link>
    </div>
  )
}
