import React from "react"
import Img from "gatsby-image"

// stlye
import "../../styles/imgCard.css"

export default function Phase({ src, children, odd }) {
  return odd ? (
    <section className="py-10">
      <div className="max-w-1024 px-2x mx-auto flex justify-around items-center mobile:justify-start flex-wrap">
        <div className="w-500 mobile:w-full">{children}</div>
        <div className="bg-center bg-cover w-300 h-300 relative">
          <Img className="h-full w-full absolute mobile:mt-6" fluid={src} />
        </div>
      </div>
    </section>
  ) : (
    <section className="w-full bg-lightBlue py-10">
      <div className="max-w-1024 px-2x mx-auto flex justify-around items-center mobile:justify-start flex-wrap-reverse">
        <div className="bg-center bg-cover w-300 h-300 relative">
          <Img className="h-full w-full absolute mobile:mt-6" fluid={src} />
        </div>
        <div className="w-500 mobile:w-full">{children}</div>
      </div>
    </section>
  )
}
