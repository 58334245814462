import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

// svg
import Map from "../../svg/map"

const CaseStudy = props => {
  return (
    <div id="" className="relative overflow-hidden">
      <div
        className="absolute desktop:w-full mobile:w-1024 mobile:right-0 mobile:left-0 mx-auto top-0 bottom-0 flex items-center opacity-25"
        style={{ zIndex: "-10" }}
      >
        <Map />
      </div>
      <div className="container mx-auto w-full flex justify-center pt-24 pb-32 mobile:px-6">
        <div>
          <h1 className="heading">Case Study</h1>
          <hr
            className="w-16 border border-blue-500 my-6"
            style={{ borderTop: "3px solid #1973CE" }}
          />
          {/* <Link
          to={`/${props.data?.allContentfulFeaturedProject?.edges[0]?.node?.name}`}
        > */}
          <div className="max-w-450 rounded-xl mb-10">
            <Img
              className="h-full w-full"
              fluid={
                props.data?.allContentfulFeaturedProject?.edges[0]?.node
                  ?.previewImage.fluid
              }
            />
            <div className="mt-6">
              <h4 className="heading text-2xl">
                {props.data?.allContentfulFeaturedProject?.edges[0]?.node?.name}
              </h4>
              <p className="mt-2 body">
                {
                  props.data?.allContentfulFeaturedProject?.edges[0]?.node
                    ?.summary.summary
                }
              </p>
            </div>
          </div>
          <Link to="/">
            <span className="body bg-lightGreen px-4 py-2 text-lg">
              Tell us about your next project
            </span>
          </Link>
        </div>
        {/* </Link> */}
      </div>
    </div>
  )
}

export default CaseStudy
