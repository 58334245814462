import React from "react"

// components
import ImgCard from "../layout/imgCard"
import Phase from "./phase"

const Steps = ({ designSRC, installSRC, testSRC, acceptSRC, lang }) => {
  const {
    title,
    body,
    phases,
    design,
    installation,
    testing,
    acceptance,
  } = lang

  return (
    <>
      <section id="" className="container max-w-1024 my-16 px-2x">
        <div className="text-center">
          <h1 className="heading">{title}</h1>
          <p className="body mt-6">{body}</p>
        </div>
        <div className="flex justify-between mt-8">
          <ImgCard title={<>{phases[0]}</>} src={designSRC} />
          <ImgCard title={<>{phases[1]}</>} src={installSRC} />
          <ImgCard title={<>{phases[2]}</>} src={testSRC} />
          <ImgCard title={<>{phases[3]}</>} src={acceptSRC} />
        </div>
      </section>
      <section>
        <Phase src={designSRC} odd={true}>
          <h1 className="heading">{design.title}</h1>
          <p className="body mt-4">{design.body}</p>
          <p className="heading text-lg mt-4">{design.subTitle}</p>
          <ul className="body mt-2 list-decimal text-sm space-y-1">
            <li className="m-0">{design.involvement[0]}</li>
            <li>{design.involvement[1]}</li>
            <li>{design.involvement[2]}</li>
            <li>{design.involvement[3]}</li>
            <li>{design.involvement[4]}</li>
          </ul>
        </Phase>
        <Phase src={installSRC} odd={false}>
          <h1 className="heading">{installation.title}</h1>
          <p className="body mt-4">{installation.body}</p>
          <p className="heading text-lg mt-4">{installation.subTitle}</p>
          <ul className="body mt-2 list-decimal text-sm space-y-1">
            <li className="m-0">{installation.involvement[0]}</li>
            <li>{installation.involvement[1]}</li>
            <li>{installation.involvement[2]}</li>
          </ul>
        </Phase>
        <Phase src={testSRC} odd={true}>
          <h1 className="heading">{testing.title}</h1>
          <p className="body mt-4">{testing.body}</p>
          <p className="heading text-lg mt-4">{testing.subTitle}</p>
          <ul className="body mt-2 list-decimal text-sm space-y-1">
            <li className="m-0">{testing.involvement[0]}</li>
            <li>{testing.involvement[1]}</li>
            <li>{testing.involvement[2]}</li>
            <li>{testing.involvement[3]}</li>
            <li>{testing.involvement[4]}</li>
            <li>{testing.involvement[5]}</li>
          </ul>
        </Phase>

        <Phase src={acceptSRC} odd={false}>
          <h1 className="heading">{acceptance.title}</h1>
          <p className="body mt-4">{acceptance.body}</p>
          <p className="heading text-lg mt-4">{acceptance.subTitle}</p>
          <ul className="body mt-2 list-decimal text-sm space-y-1">
            <li className="m-0">{acceptance.involvement[0]}</li>
            <li>{acceptance.involvement[1]}</li>
            <li>{acceptance.involvement[2]}</li>
            <li>{acceptance.involvement[3]}</li>
          </ul>
        </Phase>
      </section>
    </>
  )
}

export default Steps
