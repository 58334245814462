import React from "react"
import Img from "gatsby-image"

// stlye
import "../../styles/imgCard.css"

export default function ImgCard({ src, title }) {
  return (
    <div className="image-container relative mobile:text-sm">
      <div className="bg-center bg-cover w-full h-full absolute">
        <Img className="h-full w-full" fluid={src} />
      </div>
      <div className="after flex items-center justify-center font-heading text-white text-center">
        {title}
      </div>
    </div>
  )
}
