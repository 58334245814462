import React from "react"
import Img from "gatsby-image"

// components
import HeaderBtn from "../layout/headerButtons"

const Landing = ({ fluid, lang, langType }) => {
  const { headerTitle, headerBody, subSectionTitle, subSectionBody } = lang
  return (
    <>
      <section className="w-full h-550 relative desktop:mt-32">
        <div
          className="absolute max-w-1024 mx-auto left-0 right-0 desktop:px-2x h-550"
          style={{ zIndex: "-10" }}
        >
          <Img className="h-full" fluid={fluid} loading="eager" />
        </div>
        <div className="mobile:mt-24 desktop:p-10 desktop:pl-16 mobile:p-6 container max-w-1024 h-550">
          <div className="max-w-550 ">
            <div>
              <h1 className="font-heading text-white">{headerTitle}</h1>

              <p className="font-body text-white">{headerBody[0]}</p>
              <p className="font-body text-white">{headerBody[1]}</p>
            </div>
            <HeaderBtn
              linkTo={
                langType === "en"
                  ? "/building-commissioning/#more-info"
                  : "/es/building-commissioning/#more-info"
              }
            />
          </div>
        </div>
      </section>
      <section className="desktop:mt-10 desktop:max-w-1024 desktop:px-2x mx-auto">
        <div className="bg-lightBlue p-10 desktop:flex desktop:justify-between desktop:items-start text-darkerBlue ">
          <h2 className="heading desktop:w-2/12">{subSectionTitle}</h2>
          <p className="body desktop:w-10/12 mobile:mt-4">{subSectionBody}</p>
        </div>
      </section>
    </>
  )
}

export default Landing
